import merge from "lodash/merge"
import { useEffect, useState } from "react"

import { Steps } from "./steps/Steps"
import { IssueWrapper } from "./steps/NoSteps"
import { themeOverrides } from "./theme"
import { Providers } from "./components/Providers"
import type { RsiProps } from "./types"
import { ModalWrapper } from "./components/ModalWrapper"
import { translations } from "./translationsRSIProps"

export const defaultTheme = themeOverrides

export const defaultRSIProps: Partial<RsiProps<any>> = {
  autoMapHeaders: true,
  allowInvalidSubmit: true,
  autoMapDistance: 2,
  translations: translations,
  uploadStepHook: async (value) => value,
  selectHeaderStepHook: async (headerValues, data) => ({ headerValues, data }),
  matchColumnsStepHook: async (table) => table,
  dateFormat: "yyyy-mm-dd",
  parseRaw: false,
} as const

export const Pulter = <T extends string>(props: RsiProps<T>) => {
  const [issue, setIssue ] = useState(false);

  useEffect(() => {
    console.log(props);
    if (!props.templateId) {
      console.log("No template specified");
      setIssue(true);
    }
    if (props.issue === true) {
      setIssue(true);
    }
  }, [issue]);

  const mergedTranslations =
    props.translations !== translations ? merge(translations, props.translations) : translations
  const mergedThemes = merge(defaultTheme, props.customTheme)

  return (
    <>
      {console.log("merged", issue)}
      <Providers theme={mergedThemes} rsiValues={{ ...props, translations: mergedTranslations }}>
      {issue === false ? <ModalWrapper isOpen={props.isOpen} onClose={props.onClose}>
        <Steps />
      </ModalWrapper>
      :
      <IssueWrapper />}
    </Providers>
    </>
  )
}

Pulter.defaultProps = defaultRSIProps
