import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
  } from "@chakra-ui/react"
  import { useRef } from "react"
  import { useRsi } from "../../hooks/useRsi"
  
  interface Props {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
  }
  
  export const ConfigIssueAlert = ({ isOpen, onClose, onConfirm }: Props) => {
    const { translations } = useRsi()
    const cancelRef = useRef<HTMLButtonElement | null>(null)
  
    const handleClose = () => {
      window.parent.postMessage({ type: "importer-error" }, "*")
      onConfirm()
    }
  
    return (
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered id="rsi">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{translations.alerts.configIssue.headerTitle}</AlertDialogHeader>
            <AlertDialogBody>{translations.alerts.configIssue.bodyText}</AlertDialogBody>
            <AlertDialogFooter>
              {/* <Button ref={cancelRef} onClick={onClose} variant="secondary">
                {translations.alerts.confirmClose.cancelButtonTitle}
              </Button> */}
              <Button colorScheme="red" onClick={handleClose} ml={3}>
                {translations.alerts.configIssue.exitButtonTitle}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
  }
  