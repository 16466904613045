import type XLSX from "xlsx"
import { Box, Heading, ModalBody, Text, Center, useStyleConfig, Link } from "@chakra-ui/react"
import { DropZone } from "./components/DropZone"
import { useRsi } from "../../hooks/useRsi"
import { ExampleTable } from "./components/ExampleTable"
import { useCallback, useState } from "react"
import { FadingOverlay } from "./components/FadingOverlay"
import type { themeOverrides } from "../../theme"
import { exportToCsv } from "../../utils/exportUtils"
import Papa from "papaparse"

type UploadProps = {
  onContinue: (data: XLSX.WorkBook) => Promise<void>
}

export const UploadStep = ({ onContinue }: UploadProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyleConfig("UploadStep") as typeof themeOverrides["components"]["UploadStep"]["baseStyle"]
  const { translations, fields } = useRsi()
  const handleOnContinue = useCallback(
    async (data: any) => {
      setIsLoading(true)
      await onContinue(data)
      setIsLoading(false)
    },
    [onContinue],
  )

  const remoteCSV = (event: React.MouseEvent<HTMLInputElement>) => {
    alert(event)
    console.log(event.currentTarget.value);
    Papa.parse(event.currentTarget.value, {
      complete: async function (results) {
        alert(results)
        console.log(results);
      },
    })
  }
  return (
    <ModalBody>
      <Heading sx={styles.heading}>{translations.uploadStep.title}</Heading>
      <Text sx={styles.title}>{translations.uploadStep.manifestTitle}</Text>
      <Text sx={styles.subtitle}>{translations.uploadStep.manifestDescription}</Text>
      <Box sx={styles.tableWrapper}>
        <ExampleTable fields={fields} />
        <FadingOverlay />
      </Box>
      <DropZone onContinue={handleOnContinue} isLoading={isLoading} />
      <br />
      <Center>
        <Text fontSize='sm'>
          <Link href='#' onClick={() => exportToCsv(<ExampleTable fields={fields} />, 'ExampleFile.csv')}>
            Download sample CSV file
          </Link>
        </Text>
      </Center>
      {/* <div style={styles.searchWrap}>
        <div style={styles.searchBox}>
          <input type="text" style={styles.searchInput} placeholder="search..." />
          <div style={styles.searchBtn} onClick={() => remoteCSV}>
            <p>Search</p>
          </div>
        </div>
      </div> */}
    </ModalBody>
  )
}