import { useCallback, useState } from "react"
import { Progress, useToast } from "@chakra-ui/react"
import type XLSX from "xlsx"
import { UploadStep } from "./UploadStep/UploadStep"
import { SelectHeaderStep } from "./SelectHeaderStep/SelectHeaderStep"
import { SelectSheetStep } from "./SelectSheetStep/SelectSheetStep"
import { mapWorkbook } from "../utils/mapWorkbook"
import { ValidationStep } from "./ValidationStep/ValidationStep"
import { MatchColumnsStep } from "./MatchColumnsStep/MatchColumnsStep"
import { exceedsMaxRecords } from "../utils/exceedsMaxRecords"
import { useRsi } from "../hooks/useRsi"
import type { RawData } from "../types"
// import { get, initializeDB, set } from "../utils/csvDb"

export enum StepType {
  upload = "upload",
  selectSheet = "selectSheet",
  selectHeader = "selectHeader",
  matchColumns = "matchColumns",
  validateData = "validateData",
}

export const dataMap = new Map();
export type StepState =
  | {
    type: StepType.upload
    // data: any[]
  }
  | {
    type: StepType.selectSheet
    workbook: XLSX.WorkBook
  }
  | {
    type: StepType.selectHeader
    data: RawData[]
  }
  | {
    type: StepType.matchColumns
    data: RawData[]
    headerValues: RawData
  }
  | {
    type: StepType.validateData
    data: any[]
    headerValues: RawData
  }

interface Props {
  nextStep: () => void
  prevStep: () => void
}

export const UploadFlow = ({ nextStep, prevStep }: Props) => {
  const { initialStepState } = useRsi()
  const [state, setState] = useState<StepState>(initialStepState || { type: StepType.upload })
  const { maxRecords, translations, uploadStepHook, selectHeaderStepHook, matchColumnsStepHook } = useRsi()
  const toast = useToast()
  const errorToast = useCallback(
    (description: string) => {
      toast({
        status: "error",
        variant: "left-accent",
        position: "bottom-left",
        title: `${translations.alerts.toast.error}`,
        description,
        isClosable: true,
      })
    },
    [toast, translations],
  )

  switch (state.type) {
    case StepType.upload:
      return (
        <UploadStep
        // initialData={state.data}
          onContinue={async (workbook) => {
            const isSingleSheet = workbook.SheetNames.length === 1
            if (isSingleSheet) {
              if (maxRecords && exceedsMaxRecords(workbook.Sheets[workbook.SheetNames[0]], maxRecords)) {
                errorToast(translations.uploadStep.maxRecordsExceeded(maxRecords.toString()))
                return
              }
              try {
                const mappedWorkbook = await uploadStepHook(mapWorkbook(workbook))
                dataMap.set("workbook", JSON.stringify(mappedWorkbook));
                setState({
                  type: StepType.selectHeader,
                  data: mappedWorkbook,
                })
                nextStep()
              } catch (e) {
                errorToast((e as Error).message)
              }
            } else {
              setState({ type: StepType.selectSheet, workbook })
            }
          }}
        />
      )
    case StepType.selectSheet:
      return (
        <SelectSheetStep
          sheetNames={state.workbook.SheetNames}
          onContinue={async (sheetName) => {
            if (maxRecords && exceedsMaxRecords(state.workbook.Sheets[sheetName], maxRecords)) {
              errorToast(translations.uploadStep.maxRecordsExceeded(maxRecords.toString()))
              return
            }
            try {
              const mappedWorkbook = await uploadStepHook(mapWorkbook(state.workbook, sheetName))
              dataMap.set("sheetName", sheetName);
              dataMap.set("workbook", JSON.stringify(mappedWorkbook));
              setState({
                type: StepType.selectHeader,
                data: mappedWorkbook,
              })
              nextStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
          onPrevious={() => {
            try {
              setState({
                type: StepType.upload,
              })
              prevStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
        />
      )
    case StepType.selectHeader:
      return (
        <SelectHeaderStep
          data={state.data}
          onContinue={async (...args) => {
            try {
              const { data, headerValues } = await selectHeaderStepHook(...args)
              setState({
                type: StepType.matchColumns,
                data,
                headerValues,
              })
              nextStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
          onPrevious={() => {
            try {
              setState({
                type: StepType.upload,
              })
              prevStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
        />
      )
    case StepType.matchColumns:
      return (
        <MatchColumnsStep
          data={state.data}
          headerValues={state.headerValues}
          onContinue={async (values, rawData, columns) => {
            try {
              dataMap.set("_pultr_headerVal", JSON.stringify(state.headerValues));
              dataMap.set("_pultr_data", JSON.stringify(state.data));
              const data = await matchColumnsStepHook(values, rawData, columns)
              setState({
                type: StepType.validateData,
                data,
                headerValues: state.headerValues,
              })
              nextStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
          onPrevious={async () => {
            try {
              const workbook = JSON.parse(dataMap.get("workbook") || JSON.stringify(state.data))
              setState({
                type: StepType.selectHeader,
                data: workbook,
              })
              prevStep()
            } catch (e) {
              errorToast((e as Error).message)
            }
          }}
        />
      )
    case StepType.validateData:
      return <ValidationStep
        initialData={state.data}
        onPrevious={async () => {
          try {
            const header = JSON.parse(dataMap.get("_pultr_headerVal") || JSON.stringify(state.headerValues));
            const data = JSON.parse(dataMap.get("_pultr_data") || JSON.stringify(state.data));
            setState({
              type: StepType.matchColumns,
              data: data,
              headerValues: header,
            })
            prevStep()
          } catch (e) {
            errorToast((e as Error).message)
          }
        }}
      />
    default:
      return <Progress isIndeterminate />
  }
}