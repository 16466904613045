import { useEffect } from "react"
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';
import { Pulter } from "./ReactSpreadsheetImport"
import { Box, Link, Code, Button, useDisclosure, useToast, Center, useStyleConfig } from "@chakra-ui/react"
import { mockRsiValues } from "./stories/mockRsiValues";
import { useState } from "react";
import axios, { AxiosResponse } from "axios";

import { themeOverrides } from "./theme"

const BASE_URL = process.env.REACT_APP_API_URL;
const SEND_RECORDS = `${BASE_URL}/template/send`;

interface customer {
  [key: string]: any
}

export default {
  title: "Pulter",
}

export const Basic = () => {
  const [data, setData] = useState<any>(null)
  const [values, setValues] = useState<any>(null)
  const [templateId, setTemplateId] = useState<any>(null)
  const [customer, setCustomer] = useState<customer>({})
  const [rowHook, setRowHook] = useState<any>(undefined)
  const [tableHook, setTableHook] = useState<any>(undefined)
  const [uploadStepHook, setUploadStepHook] = useState<any>(undefined)
  const [selectHeaderStepHook, setSelectHeaderStepHook] = useState<any>(undefined)
  const [matchColumnsStepHook, setMatchColumnsStepHook] = useState<any>(undefined)
  const [dataSync, setDataSync] = useState<boolean>(true)
  const [plan, setPlan] = useState<any>(undefined)
  const [user, setUser] = useState<any>(undefined)
  const [templateName, setTemplateName] = useState<any>(undefined)
  const [issue, setIssue] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState<any>([]);
  const styles = themeOverrides["colors"]
  // const [openModal, setOpenModal] = useState<boolean>(true)
  // eslint-disable-next-line prefer-const
  let { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const templId = params.get("templateId") || 'no templateId';
    setTemplateId(templId);
    localStorage.setItem("templateId", templId);
    GetUserPlan(params.get("templateId"));
    window.addEventListener(
      'message',
      (event) => {
        // if(debugMode) { console.log(`[Csvbox-${this.uuid}]`, "Message:", event); }
        // alert(event.data);
        console.log('Config from web', event.data);
        if (typeof event.data === 'object') {
          if (event.data.type && event.data.type === 'userConfig') {
            // setOpenModal(event.data.data.isOpen);
            if (event.data.data.isOpen === true) {
              onOpen();
            };
            // setTemplateId(event.data.templateId);
            // console.log('initial data sync', event.data.dataSync);
            setCustomer(event.data.user);
            const user = JSON.stringify(event.data.user);
            const metadata = JSON.stringify(event.data.pulter_metadata || {});
            localStorage.setItem('pulter_user', user);
            localStorage.setItem('pulter_metadata', metadata);
            setDataSync(event.data.dataSync);
            const newValues: any = {};
            Object.keys(event.data.data).forEach((key, index) => {
              if (event.data.data[key] !== null) {
                newValues[key] = event.data.data[key];
              }
            });
            // console.log(fields);
            // newValues.fields.concat(fields);
            // console.log(newValues);
            Object.assign(mockRsiValues, newValues);
            // console.log(mockRsiValues);
            setValues(mockRsiValues);
          }
          if (event.data.type && event.data.type === 'uploadStepHook') {
            setUploadStepHook(event.data.data.uploadStepHook);
          }
          if (event.data.type && event.data.type === 'selectHeaderStepHook') {
            setSelectHeaderStepHook(event.data.data.selectHeaderStepHook)
          }
          if (event.data.type && event.data.type === 'matchColumnsStepHook') {
            setMatchColumnsStepHook(event.data.data.matchColumnsStepHook)
          }
          if (event.data.type && event.data.type === 'rowHook') {
            setRowHook(event.data.data.rowHook);
          }
          if (event.data.type && event.data.type === 'tableHook') {
            setTableHook(event.data.data.tableHook);
          }
        }
      },
      false
    );
    if (data) {
      window.parent.postMessage({ type: "submitData", data: { ...data } }, "*");
      data.user = customer;
      if (dataSync === true || dataSync === undefined) {
        // uploadCSV(data);
        // SendRecords(templateId, data);
        // console.log('Sending record')

      }
    }
  }, [data])

  const concatArr = () => {
    // console.log(fields);
    if (mockRsiValues.fields.length > 0) {
      return mockRsiValues;
    }
    const newValue = mockRsiValues.fields.concat(fields);
    // console.log(newValue);
    mockRsiValues.fields = newValue;
    return mockRsiValues;
  }

  const SendRecords = (templateId: string, data: any) => {
    axios.post(`https://api.pulter.co/api/v1/send/records/${templateId}`, data)
      .then((response: AxiosResponse) => {
        console.log(response);
        if (response.status === 200) {
          // toast({
          //   status: "success",
          //   variant: "left-accent",
          //   position: "bottom-left",
          //   title: 'Success',
          //   description: 'Records sent successfully',
          //   isClosable: true,
          //   duration: 9000,
          // });
        } else {
          console.log(response);
          // toast({
          //   status: "error",
          //   variant: "left-accent",
          //   position: "bottom-left",
          //   title: 'Error',
          //   description: 'Something went wrong, please try again',
          //   isClosable: true,
          // });
        }
        // console.log(response);
      }).catch((error) => {
        // console.log(error);
        toast({
          title: "Error",
          description: "Something went wrong, please try again",
          status: "error",
          variant: "left-accent",
          position: "bottom-left",
          isClosable: true,
        });
      });
  };

  const GetUserPlan = (templateId: any) => {

    axios.get(`${SEND_RECORDS}/${templateId}`)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          // console.log(response.data);
          const resp = response.data.data.response;
          // console.log(resp.fields);
          const { customTheme, maxRecords, dataSync } = resp;
          console.log('theme', customTheme);
          const dataConfig = {
            customTheme,
            maxRecords,
            dataSync
          }
          Object.assign(mockRsiValues, dataConfig);
          setPlan(resp.user.plan);
          setUser(resp.user);
          mockRsiValues.fields.concat(fields);
          setFields(resp.fields);
          setTemplateName(resp.templateName);
          setLoading(false);
        } else {
          setIssue(true);
        }
        // console.log(response);
      }).catch((error) => {
        setIssue(true);
      });
  };

  return (
    <>
      {!loading &&
        <>
          <Box>
            <Center>
              <Button sx={{'background': styles.rsi[500], 'color': '#fff'}} size="lg" onClick={onOpen} p="8px" borderRadius="8px">
                Import {templateName}
              </Button>
            </Center>
            <Pulter
              {...concatArr()}
              isOpen={isOpen}
              templateId={templateId}
              uploadStepHook={new Function(`return ${uploadStepHook}`)()}
              matchColumnsStepHook={new Function(`return ${matchColumnsStepHook}`)()}
              selectHeaderStepHook={new Function(`return ${selectHeaderStepHook}`)()}
              rowHook={new Function(`return ${rowHook}`)()}
              tableHook={new Function(`return ${tableHook}`)()}
              onClose={onClose}
              onSubmit={setData}
              plan={plan}
              user={user}
              issue={issue}
            />
          </Box>
        </>
      }
    </>
  )
}
