import axios, { AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";
import * as crypto from 'crypto-hash';

const BASE_URL = 'https://api.pulter.co/api/v1';
const SEND_RECORDS = `${BASE_URL}/send/records`;

interface dataLayout {
    [key: string]: any
}

const breakIntoBatches = async (arr: any[], batchSize: number) => {
    let batches = [];
    for (let i = 0; i < arr.length; i += batchSize) {
      batches.push(arr.slice(i, i + batchSize));
    }
    return batches;
}

const headers = {
    'Content-Type': 'application/json',
    Accepts: 'application/json',
  };

const sendWebhook = async (recordId: string, data: dataLayout, templateId: string) => {
    const totalRows = data['all'].length / 5000;
    if (totalRows > 0) {
      for (let i = 0; i <= totalRows; i++) {
        const arrBatch = await breakIntoBatches(data['all'], 5000);
        arrBatch.push(data['user']);
        arrBatch.push(data['metadata']);
        const batch = {
          id: recordId,
          templateId,
          index: i + 1,
          count: totalRows,
          totalRows: data['all'].length
        }
        // await uploadWebhook(recordId, arrBatch, templateId, false, webhooks, batch);
      }
    } else {
      const arrBatch = data['all'];
      arrBatch.push(data['user']);
      arrBatch.push(data['metadata']);
      const batch = {
        id: recordId,
        templateId,
        index: 1,
        count: 1,
        totalRows: data['all']
      }
      // await uploadWebhook(recordId, arrBatch, templateId, false, webhooks, batch);
    }
  };

export const webhookUtils = async (data: any, alldata: any, user: any, metadata: any, dataSync: boolean, indexId: string, totalRows: number, event: string, webhookUrl?: string) => {
    const templateId = localStorage.getItem('templateId') as string;
    if(dataSync) {
        const newData = {
            ...alldata,
            user,
            metadata
        }
        const resp = await SendRecords(templateId, newData);
        return resp;
    }
    if(webhookUrl && webhookUrl !== undefined) {
        const promise = data.map(async (record: any, index: number) => {
            const newData = {
                data: record,
                user,
                metadata,
            };
            const batch = {
                id: indexId,
                templateId,
                index: index + 1,
                count: data.length,
                totalRows
            }

            await uploadSingleEv(newData, batch, event, templateId, webhookUrl);
        });
    
        await Promise.all(promise);
        return {
            status: 200,
            message: 'Successfully uploaded',
        }
    }
}

export const SendRecords = async (templateId: string, data: any) => {
    // const toast = useToast();
    // const body = JSON.stringify(data);
    let webResp: any = {};


    axios.post(`${SEND_RECORDS}/${templateId}`, data)
        .then((response: AxiosResponse) => {
            if (response.status === 200) {
                webResp = {
                    status: 200,
                    message: 'successfully sent'
                }

                return webResp;
            } else {
                webResp = {
                    status: response.status,
                    message: response.statusText
                }

                return webResp;
            }
        }).catch((error) => {
            console.log(error);
            webResp = {
                status: 500,
                message: 'Something went wrong'
            }

            return webResp;
        });

    return webResp;
};

const uploadSingleEv = async (data: dataLayout, batch: any, event: string, templateId: string, webhookUrl: string) => {
    let webResp = '';
    // const parsedEvent = Object.entries(data).reduce(
    //   (acc: Record<string, any>, [key, eventValue]) => {
    //     acc[key] = eventValue.data ? eventValue.data : eventValue;
    //     return acc;
    //   },
    //   {}
    // );
    let eventPayload = JSON.stringify({
      ...batch,
      event_name: event,
      event_data: data,
    });
    // console.log(eventPayload);
    let hmac = await generateHmac(templateId);
    const webhookRequest = fetch(webhookUrl, {
      method: 'POST',
      body: eventPayload,
      headers: { ...headers, "X-Pultr-Signature": hmac }
    })
      .then(async (response) => {
        if (!response.ok) {
          webResp = response.statusText;
          console.error('error making webhook request', response.statusText);
          return {
            status: response.status,
            message: response.statusText
          };
        }
        let statusText = (response.status >= 200 && response.status <= 299) ? "OK" : response.statusText;
        let log = {
          statusText: statusText,
          status: response.status,
          date: new Date(),
        };
        console.log(log);
        return {
            status: 200,
            message: 'successfukky sent'
        };
      })
      .catch(async (error) => {
        const health = {
          ...error,
          date: new Date(),
        }
        let statusText = 'NOT OK';
        webResp = statusText;
        console.error('error making webhook request', error);
        return {
            status: 500,
            message: statusText
        };
      });
}

let generateHmac = async function (secret: any) {
    // const msgUint8 = CryptoJS.AES.encrypt("Message", "Secret Passphrase");
    const hmac = await crypto.sha256(secret);
    // hmac.update(body);
    // return hmac.digest('hex');
    return hmac;
};