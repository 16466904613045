import { cloneElement } from 'react';
import type { ReactElement } from 'react';
import type { DataGridProps } from "react-data-grid"
import Papa from 'papaparse';

export async function exportToCsv<R, SR>(
    gridElement: ReactElement<DataGridProps<R, SR>>,
    fileName: string
) {
    const { head, body, foot } = await getGridContent(gridElement);
    const content = [...head, ...body, ...foot]
        .map((cells) => cells.map(serialiseCellValue).join(','))
        .join('\n');

    downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

export async function exportToXlsx<R, SR>(
    gridElement: ReactElement<DataGridProps<R, SR>>,
    fileName: string
) {
    const [{ utils, writeFile }, { head, body, foot }] = await Promise.all([
        import('xlsx'),
        getGridContent(gridElement)
    ]);
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([...head, ...body, ...foot]);
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, fileName);
}

//   export async function exportToPdf<R, SR>(
//     gridElement: ReactElement<DataGridProps<R, SR>>,
//     fileName: string
//   ) {
//     const [{ jsPDF }, autoTable, { head, body, foot }] = await Promise.all([
//       import('jspdf'),
//       (await import('jspdf-autotable')).default,
//       await getGridContent(gridElement)
//     ]);
//     const doc = new jsPDF({
//       orientation: 'l',
//       unit: 'px'
//     });

//     autoTable(doc, {
//       head,
//       body,
//       foot,
//       horizontalPageBreak: true,
//       styles: { cellPadding: 1.5, fontSize: 8, cellWidth: 'wrap' },
//       tableWidth: 'wrap'
//     });
//     doc.save(fileName);
//   }

async function getGridContent<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>) {
    const { renderToStaticMarkup } = await import('react-dom/server');
    const grid = document.createElement('div');
    grid.innerHTML = renderToStaticMarkup(
        cloneElement(gridElement, {
            enableVirtualization: false
        })
    );

    return {
        head: getRows('.rdg-header-row'),
        body: getRows('.rdg-row:not(.rdg-summary-row)'),
        foot: getRows('.rdg-summary-row')
    };

    function getRows(selector: string) {
        return Array.from(grid.querySelectorAll<HTMLDivElement>(selector)).map((gridRow) => {
            return Array.from(gridRow.querySelectorAll<HTMLDivElement>('.rdg-cell')).map(
                (gridCell) => gridCell.innerText
            );
        });
    }
}

function serialiseCellValue(value: unknown) {
    if (typeof value === 'string') {
        const formattedValue = value.replace(/"/g, '""');
        return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
    }
    return value;
}

function downloadFile(fileName: string, data: Blob) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}

export async function manualExport(fileName: string, data: any[]) {
    data.forEach((x) => {
        const keys = Object.keys(x);
        keys.forEach((obj) => {
          x[obj] = typeof x[obj] === 'object' ? JSON.stringify(x[obj]) : x[obj];
        });
    });
    const csv = Papa.unparse(data);
    // let csv = ''

    // for (let row = 0; row < data.length; row++) {
    //     let keysAmount = Object.keys(data[row]).length
    //     let keysCounter = 0

    //     if (row === 0) {

    //         for (let key in data[row]) {
    //             csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
    //             keysCounter++
    //         }
    //     } else {
    //         for (let key in data[row]) {
    //             csv += data[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
    //             keysCounter++
    //         }
    //     }

    //     keysCounter = 0
    // }

    manualDownload(fileName, csv);
}

function manualDownload(fileName: string, data: string) {
    let link = document.createElement('a')
    // link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    link.setAttribute('download', fileName);
    document.body.appendChild(link)
    // document.querySelector('#download-csv').click()
    link.click()
}