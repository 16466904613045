import {
    Center,
    Box,
    HStack,
    Heading,
    Text,
    Spinner,
} from "@chakra-ui/react";
import { DownloadIcon, CheckCircleIcon } from '@chakra-ui/icons'
import { useRef, useEffect } from "react"
import { useRsi } from "../../hooks/useRsi"
import { themeOverrides } from "../../theme"
import { manualExport } from "../../utils/exportUtils"

interface Props {
    downloadCSV: boolean
    all: any[]
    valid: any[]
    invalid: any[]
    response: any
}

export const ImportComplete = ({ downloadCSV, all, valid, invalid, response }: Props) => {
    const { allowInvalidSubmit, translations } = useRsi()
    const styles = themeOverrides["colors"]
    const cancelRef = useRef<HTMLButtonElement | null>(null)
    console.log(response);

    useEffect(() => {
        localStorage.setItem('pulter_step', 'import_complete');
    }, []);

    return (
        <Center paddingTop={40}>
            <Box>
                <Center>
                    <CheckCircleIcon boxSize={8} color='green.500' />
                </Center>
                <Center>
                    <Text fontSize='lg'>Data Successfully Imported!</Text>
                </Center>
                <br />
                {downloadCSV && <HStack color={styles.rsi[500]}>
                    <Box p={2} sx={{ cursor: 'pointer' }} onClick={() => manualExport('all.csv', all)}>
                        <HStack>
                            <DownloadIcon boxShadow='outline' rounded='md' />
                            <Text fontSize='16px'>all .csv file</Text>
                        </HStack>
                    </Box>
                    <Box p={2} sx={{ cursor: 'pointer' }} onClick={() => manualExport('valid.csv', valid)}>
                        <HStack>
                            <DownloadIcon />
                            <Text fontSize='16px'>Only valid .csv file</Text>
                        </HStack>
                    </Box>
                    <Box p={2} sx={{ cursor: 'pointer' }} onClick={() => manualExport('invalid.csv', invalid)}>
                        <HStack>
                            <DownloadIcon />
                            <Text fontSize='16px'>Only invalid .csv file</Text>
                        </HStack>
                    </Box>
                </HStack>}
            </Box>
        </Center>
    )
}

export const ImportLoading = () => {

    return (
        <Center paddingTop={40}>
            <Box>
                <Center>
                    <Text fontSize='lg'>Importing Data!</Text>
                </Center>
                <Center>
                    <Spinner boxSize={8} />
                </Center>
            </Box>
        </Center>
    )
}
