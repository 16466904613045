import { Grid, GridItem, Button, Link, ModalFooter, Text } from "@chakra-ui/react"

type ContinueButtonProps = {
  onContinue: (val: any) => void
  onPrevious: () => void
  title: string
  isLoading?: boolean
}

export const ContinueButton = ({ onContinue, onPrevious, title, isLoading }: ContinueButtonProps) => (
  <ModalFooter sx={{ display: 'block' }}>
    {/* <Button size="lg" w="21rem" onClick={onContinue} isLoading={isLoading}>
      {title}
    </Button> */}
    <Grid templateColumns='repeat(7, 1fr)'>
      <GridItem colSpan={3}>
        <Button
          onClick={onPrevious}
          size="md"
          variant="ghost"
        >
          Prev
        </Button>
      </GridItem>
      <GridItem colSpan={3}>
        <Text fontSize='sm'>
          <Link href='https://pulter.co'>
            Powered by Pulter
          </Link>
        </Text>
      </GridItem>
      <GridItem>
        <Button size="md" onClick={onContinue} isLoading={isLoading}>
          {title}
        </Button>
      </GridItem>
    </Grid>
  </ModalFooter>
)
