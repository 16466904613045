import type React from "react"
import type { Column, Columns } from "../MatchColumnsStep"
import {
  Box, Flex, Heading, ModalBody, Text, useStyleConfig,
} from "@chakra-ui/react"
import { FadingWrapper, BoxWrapper } from "../../../components/FadingWrapper"
import { ContinueButton } from "../../../components/ContinueButton"
import { useRsi } from "../../../hooks/useRsi"
import type { themeOverrides } from "../../../theme"

type ColumnGridProps<T extends string> = {
  columns: Columns<T>
  userColumn: (column: Column<T>) => React.ReactNode
  templateColumn: (column: Column<T>) => React.ReactNode
  onContinue: (val: Record<string, string>[]) => void
  onPrevious: () => void
  isLoading: boolean
}

export type Styles = typeof themeOverrides["components"]["MatchColumnsStep"]["baseStyle"]

export const ColumnGrid = <T extends string>({
  columns,
  userColumn,
  templateColumn,
  onContinue,
  onPrevious,
  isLoading,
}: ColumnGridProps<T>) => {
  const { translations } = useRsi()
  const styles = useStyleConfig("MatchColumnsStep") as Styles

  return (
    <>
      <ModalBody
        css={{
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D3D3D3",
            borderRadius: "24px",
          },
        }}
        flexDir="column"
        p={8}
        overflow="auto"
      >
        <Heading sx={styles.heading}>{translations.matchColumnsStep.title}</Heading>
        <Flex
          flex={1}
          display="grid"
          gridTemplateRows="auto auto auto 1fr"
          gridTemplateColumns={`0.75rem repeat(${columns.length}, minmax(18rem, auto)) 0.75rem`}
        >
          <Box gridColumn={`1/${columns.length + 3}`}>
            <Text sx={styles.title}>{translations.matchColumnsStep.userTableTitle}</Text>
          </Box>
          {columns.map((column, index) => (
            <Box gridRow="2/3" gridColumn={`${index + 2}/${index + 3}`} py="1.125rem" pl={2} pr={3} key={column.header}>
              {userColumn(column)}
            </Box>
          ))}
          <FadingWrapper gridColumn={`1/${columns.length + 3}`} gridRow="2/3" />
          <Box gridColumn={`1/3`} mt={7}>
            <Text sx={styles.title}>{translations.matchColumnsStep.templateTitle}</Text>
          </Box>
          <FadingWrapper gridColumn={`1/${columns.length + 3}`} gridRow="4/5" />
          {columns.map((column, index) => (
            <Box gridRow="4/5" gridColumn={`${index + 2}/${index + 3}`} key={column.index} py="1.125rem" pl={2} pr={3}>
              {templateColumn(column)}
            </Box>
          ))}
        </Flex>
      </ModalBody>
      <ContinueButton
        isLoading={isLoading}
        onContinue={onContinue}
        onPrevious={onPrevious}
        title={translations.matchColumnsStep.nextButtonTitle}
      />
    </>
  )
}
