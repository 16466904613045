import type React from "react"
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { ModalIssueButton } from "../components/ModalIssueButton";

type Props = {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
}

export const IssueWrapper = () => {
  console.log("IssueWrapper");
  return (
    <ModalIssueButton onClose={() => {}} />
  )
}