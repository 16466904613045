import { useCallback, useMemo, useState } from "react"
import { Box, Button, Heading, ModalBody, Switch, useStyleConfig } from "@chakra-ui/react"
import { v4 } from 'uuid';
import { ContinueButton } from "../../components/ContinueButton"
import { useRsi } from "../../hooks/useRsi"
import type { Meta } from "./types"
import { addErrorsAndRunHooks } from "./utils/dataMutations"
import { generateColumns } from "./components/columns"
import { Table } from "../../components/Table"
import { SubmitDataAlert } from "../../components/Alerts/SubmitDataAlert"
import { ImportCompleteAlert } from "../../components/Alerts/ImportCompleteAlert"
import { ImportComplete, ImportLoading } from "../../components/Alerts/ImportCompletePage"
import type { Data } from "../../types"
import type { themeOverrides } from "../../theme"
import type { RowsChangeData } from "react-data-grid"
import { webhookUtils } from "../../utils/webhook";

type Props<T extends string> = {
  initialData: Data<T>[]
  onPrevious: () => void
}

interface dataLayout {
  [key: string]: any
}

export const ValidationStep = <T extends string>({ initialData, onPrevious }: Props<T>) => {
  const { translations, fields, onClose, onSubmit, rowHook, tableHook, webhookUrl, allowInvalidSubmit = true, dataSync = true, downloadCSV = true } = useRsi<T>()
  const styles = useStyleConfig("ValidationStep") as typeof themeOverrides["components"]["ValidationStep"]["baseStyle"]

  const [data, setData] = useState<(Data<T> & Meta)[]>(
    useMemo(
      () => addErrorsAndRunHooks<T>(initialData, fields, rowHook, tableHook),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  )
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number | string>>(new Set())
  const [filterByErrors, setFilterByErrors] = useState(false)
  const [showSubmitAlert, setShowSubmitAlert] = useState(false)
  const [showCompleteAlert, setShowCompleteAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [completedata, setCompletedata] = useState<dataLayout>({});
  const [dataResponse, setDataResponse] = useState<any>(null);

  const updateData = useCallback(
    (rows: typeof data) => {
      setData(addErrorsAndRunHooks<T>(rows, fields, rowHook, tableHook))
    },
    [setData, rowHook, tableHook, fields],
  )

  const deleteSelectedRows = () => {
    if (selectedRows.size) {
      const newData = data.filter((value) => !selectedRows.has(value.__index))
      updateData(newData)
      setSelectedRows(new Set())
    }
  }

  const breakIntoBatches = async (arr: any[], batchSize: number) => {
    let batches = [];
    for (let i = 0; i < arr.length; i += batchSize) {
      batches.push(arr.slice(i, i + batchSize));
    }
    return batches;
  }

  const closeModal = () => {
    window.parent.postMessage({ type: "uploadSuccessful" }, "*")
    onClose()
  }

  const updateRow = useCallback(
    (rows: typeof data, changedData?: RowsChangeData<typeof data[number]>) => {
      const changes = changedData?.indexes.reduce((acc, index) => {
        // when data is filtered val !== actual index in data
        const realIndex = data.findIndex((value) => value.__index === rows[index].__index)
        acc[realIndex] = rows[index]
        return acc
      }, {} as Record<number, typeof data[number]>)
      const newData = Object.assign([], data, changes)
      updateData(newData)
    },
    [data, updateData],
  )

  const columns = useMemo(() => generateColumns(fields), [fields])

  const tableData = useMemo(() => {
    if (filterByErrors) {
      return data.filter((value) => {
        if (value?.__errors) {
          return Object.values(value.__errors)?.filter((err) => err.level === "error").length
        }
        return false
      })
    }
    return data
  }, [data, filterByErrors])

  const rowKeyGetter = useCallback((row: Data<T> & Meta) => row.__index, [])

  const submitData = async () => {
    setShowLoading(true);
    const all = data.map(({ __index, __errors, ...value }) => ({ ...value })) as unknown as Data<T>[]
    const validData = all.filter((value, index) => {
      const originalValue = data[index]
      if (originalValue?.__errors) {
        return !Object.values(originalValue.__errors)?.filter((err) => err.level === "error").length
      }
      return true
    })
    const invalidData = data.filter((value) => !validData.includes(value))
    // window.postMessage({ type: "submitData", data: { validData, invalidData, all: data  } }, "*")
    onSubmit({ validData, invalidData, all: data });
    const allData = { validData, invalidData, all: data };
    setCompletedata(allData);
    const newData: dataLayout = allData;
    // console.log('newData', newData);
    const user = JSON.parse(localStorage.getItem("pulter_user") as string);
    const metadata = JSON.parse(localStorage.getItem("pulter_metadata") as string);
    const dataObj = {
      user: user,
      metadata: metadata,
    }
    const indexId = v4();
    let response: any = {};
    if (allowInvalidSubmit){
      const arrBatch = await breakIntoBatches(newData['all'], 5000);
      const resp = await webhookUtils(
        arrBatch,
        allData,
        dataObj['user'],
        dataObj['metadata'],
        dataSync,
        indexId,
        allData['all'].length,
        'all',
        webhookUrl
      );
      response = resp;
      // arrBatch.push(...[dataObj]);
      // arrBatch[].push(dataObj['user']);
      // arrBatch.push(dataObj['metadata']);
    } else {
      const arrBatch = await breakIntoBatches(newData['validData'], 5000);
      const resp = await webhookUtils(
        arrBatch,
        allData,
        dataObj['user'],
        dataObj['metadata'],
        dataSync,
        indexId,
        newData['validData'].length,
        'validData',
        webhookUrl
      );
      // onCompleteSubmission = true;
      response = resp;
    }
    localStorage.removeItem("templateId");
    setDataResponse(response);
    setShowLoading(false);
    setShowCompleteAlert(true);
  }
  const onContinue = () => {
    const invalidData = data.find((value) => {
      if (value?.__errors) {
        return !!Object.values(value.__errors)?.filter((err) => err.level === "error").length
      }
      return false
    })
    if (!invalidData) {
      submitData()
    } else {
      setShowSubmitAlert(true)
    }
  }

  return (
    <>
      <SubmitDataAlert
        isOpen={showSubmitAlert}
        onClose={() => setShowSubmitAlert(false)}
        onConfirm={() => {
          setShowSubmitAlert(false)
          submitData()
        }}
      />
      {/* <ImportCompleteAlert
        isOpen={showCompleteAlert}
        onClose={() => closeModal()}
        onConfirm={() => {
          setShowCompleteAlert(false)
          closeModal()
        }}
      /> */}
      {showLoading && <ImportLoading />}
      {showCompleteAlert && <ImportComplete
        downloadCSV={downloadCSV}
        all={completedata.all}
        valid={completedata.validData}
        invalid={completedata.invalidData}
        response={dataResponse}
       />}
       {!showCompleteAlert && <>
       <ModalBody pb={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="2rem" flexWrap="wrap" gap="8px">
          <Heading sx={styles.heading}>{translations.validationStep.title}</Heading>
          <Box display="flex" gap="16px" alignItems="center" flexWrap="wrap">
            <Button variant="outline" size="sm" onClick={deleteSelectedRows}>
              {translations.validationStep.discardButtonTitle}
            </Button>
            <Switch
              display="flex"
              alignItems="center"
              isChecked={filterByErrors}
              onChange={() => setFilterByErrors(!filterByErrors)}
            >
              {translations.validationStep.filterSwitchTitle}
            </Switch>
          </Box>
        </Box>
        <Box h={0} flexGrow={1}>
          <Table
            rowKeyGetter={rowKeyGetter}
            rows={tableData}
            onRowsChange={updateRow}
            columns={columns}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            components={{
              noRowsFallback: (
                <Box display="flex" justifyContent="center" gridColumn="1/-1" mt="32px">
                  {filterByErrors
                    ? translations.validationStep.noRowsMessageWhenFiltered
                    : translations.validationStep.noRowsMessage}
                </Box>
              ),
            }}
          />
        </Box>
      </ModalBody>
      <ContinueButton onContinue={onContinue} onPrevious={onPrevious} title={translations.validationStep.nextButtonTitle} />
       </>}
    </>
  )
}
